body {
    background-color: #CE142B;
  }
  
  .App {
    text-align: center;
    font-size: calc(10px + 2vmin);
    color: white;
    display: flex;
    flex-direction: column;
  }
  .app-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .app-body {
  
  }
  .top-bar {
    background-color: #e2c6e9;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .top-bar h1 {
    font-size: calc(10px + 4vmin);
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .logo-a {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    color: #CE142B;
  }
  
  .logo-b {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
  }
  
  #menu-icon {
    font-size: 2rem;
  }
  
  .chat-bubble {
    background: white;
      color: #444;
      font-family: Arial;
      text-align: right;
      padding: 5px;
      margin: 10px;
      border-radius: 1rem;
      font-size: calc(5px + 3vmin);
      margin-left: 35px;
  }
  .chat-container {
    padding-top: 20px;
  }
  .chat-bubble > p {
    padding-right: 6px;
  }
  .partner a {
    text-decoration: none;
  }
  .partner img {
    max-width: 80px;
  }
  .partner p {
    color: white;
    font-weight: bold;
  }
  
  .copyright { 
    font-size: 1rem;
  }
  .copyright a {
    text-decoration: underline;
    color: white;
  }
