
.react-chatbot-kit-chat-container {
    width: 100%;
    height: 100%;
}
.react-chatbot-kit-chat-inner-container {
    height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    
    
}
.react-chatbot-kit-chat-message-container {
    width: 90%;
    flex-grow: 1;
    padding-bottom: 30px
     
 }
 
.react-chatbot-kit-chat-input-container {
    height: 50px;
    z-index: 1000;
}

.react-chatbot-kit-chat-btn-send {
    width: 60px;
    border-radius: 100%;
    background-color: #ce142b !important;
}

.react-chatbot-kit-chat-bot-message {
    width: 90%;
}


.react-chatbot-kit-user-avatar-container {
    background-color: #EFB810;
}
.chatbot, .chatbot > div, .chatbot > div > div {
    height: 100%;
}
